@font-face {
  font-family: "Seven Segment";
  src: url('../public/fonts/Seven_Segment.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
